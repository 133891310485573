import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';

export const ContactAdsPageTemplate = () => (
  <div className="ContactAds"></div>
);

const ContactAdsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout variants={['backgrounded']} showContact={true} showNewsletter={false} meta={frontmatter.meta}>
      <ContactAdsPageTemplate {...frontmatter} />
    </Layout>
  );
};

ContactAdsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactAdsPage;

export const contactAdsPageQuery = graphql`
  query ContactAdsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "estimate-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }
      }
    }
  }
`;
